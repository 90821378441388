exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-przewodnik-po-wyborze-oprogramowania-do-planowania-pracy-dla-klinik-opieki-zdrowotnej-tsx": () => import("./../../../src/pages/blog/przewodnik-po-wyborze-oprogramowania-do-planowania-pracy-dla-klinik-opieki-zdrowotnej.tsx" /* webpackChunkName: "component---src-pages-blog-przewodnik-po-wyborze-oprogramowania-do-planowania-pracy-dla-klinik-opieki-zdrowotnej-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-regulamin-tsx": () => import("./../../../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */),
  "component---src-pages-rejestracja-dziekujemy-tsx": () => import("./../../../src/pages/rejestracja/dziekujemy.tsx" /* webpackChunkName: "component---src-pages-rejestracja-dziekujemy-tsx" */),
  "component---src-pages-rejestracja-tsx": () => import("./../../../src/pages/rejestracja.tsx" /* webpackChunkName: "component---src-pages-rejestracja-tsx" */)
}

